import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useContext, useState } from "react";
import AdminContext from "../../AdminContext";
import ComboBox from "./ComboBox";
import CheckBox from "@mui/material/Checkbox";

const EmployeesSelect = (props) => {
  const adminContext = useContext(AdminContext);
  const [prevId, setPrevId] = useState();
  const [abortController, setAbortController] = useState(null);

  useEffect(() => {
    if (abortController) {
      abortController.abort();
    }

    if (
      adminContext.currentEmployee &&
      adminContext.currentEmployee.id !== prevId
    ) {
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      setPrevId(adminContext.currentEmployee.id);

      adminContext
        .getRequest(
          adminContext.constants.BASE_URL +
            "/employees/" +
            adminContext.currentEmployee.id,
          {
            signal: newAbortController.signal,
          }
        )
        .then((response) => {
          adminContext.setCurrentEmployee(response.data.employee);
          setAbortController(null);
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
            console.error("Fetch error: ", error);
          }
          setAbortController(null);
        });
    } else if (!adminContext.currentEmployee) {
      adminContext.setCurrentEmployee({});
    }
  }, [adminContext.currentEmployee]);

  const formatNameSpan = (employee) => {
    return (
      <span className={employee.is_terminated ? "text-danger" : ""}>
        <>
          {employee.last_name + ", " + employee.first_name}
          {employee.locked === true && !employee.is_terminated ? (
            <>
              {" "}
              <i className="fa-regular fa-lock"></i>
            </>
          ) : (
            ""
          )}
        </>
      </span>
    );
  };

  const employeesComboData = () => {
    let empCombo = {};
    adminContext.company.employees.map((employee) => {
      if (employee.is_deleted) {
        return;
      }
      if (
        employee.is_terminated &&
        adminContext.showTerminatedEmpSelect === false
      ) {
        return;
      }

      if (props.formatNameCallback !== undefined) {
        empCombo[employee.id] = props.formatNameCallback(employee);
      } else {
        empCombo[employee.id] = formatNameSpan(employee);
      }
    });
    return empCombo;
  };

  const unstyledEmployeesComboData = () => {
    let empCombo = {};
    adminContext.company.employees.map((employee) => {
      if (employee.is_deleted) {
        return;
      }
      if (
        employee.is_terminated &&
        adminContext.showTerminatedEmpSelect === false
      ) {
        return;
      }
      empCombo[employee.id] =
        employee.last_name +
        ", " +
        employee.first_name +
        (employee.locked === true && !employee.is_terminated
          ? " (Locked)"
          : "");
    });
    return empCombo;
  };

  return (
    <>
      <h3>{props.title ? props.title : "Selected Employee"}</h3>
      <ComboBox
        key={"currentEmployee"}
        className="mb-1"
        label={""}
        stretch={true}
        comboData={employeesComboData()}
        sortCallback={(a, b) => {
          let aValue = unstyledEmployeesComboData()[a];
          let bValue = unstyledEmployeesComboData()[b];
          if (aValue < bValue) {
            return -1;
          }
          if (aValue > bValue) {
            return 1;
          }
          return 0;
        }}
        selectedComboItem={
          adminContext.currentEmployee ? adminContext.currentEmployee.id : null
        }
        setSelectedComboItem={(data) => {
          let keyFromVal = adminContext.getKeyByValue(
            unstyledEmployeesComboData(),
            data
          );

          if (keyFromVal) {
            adminContext.setCurrentEmployee(
              adminContext.company.employees.find(
                (employee) => employee.id === Number(keyFromVal)
              )
            );
          }
        }}
      />
      <FormControlLabel
        control={
          <CheckBox
            className="mx-2"
            checked={adminContext.showTerminatedEmpSelect}
            onChange={() => {
              adminContext.setShowTerminatedEmpSelect(
                !adminContext.showTerminatedEmpSelect
              );
            }}
          />
        }
        label="Include Terminated?"
      />
    </>
  );
};
export default EmployeesSelect;
