import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import AdminContext from "../../AdminContext";
import ComboBox from "../../components/Widgets/ComboBox";
import TableWidget from "../../components/Widgets/TableWidget";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";

const AgentDashboard = () => {
  const adminContext = useContext(AdminContext);
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);

  const [statsRows, setStatsRows] = useState([]);
  const [YTDRows, setYTDRows] = useState([]);

  const [financialYearDict, setFinancialYearDict] = useState({});
  const [incomeStreamDict, setIncomeStreamDict] = useState({});

  const [financialYear, setFinancialYear] = useState("");
  const [incomeStream, setIncomeStream] = useState("");

  const initData = async () => {
    setLoading(true);
    const url = `${adminContext.constants.BASE_URL}/company/${adminContext.company.id}/agent-dashboard/init`;
    try {
      const response = await adminContext.getRequest(url);
      if (isMounted.current) {
        const financial_year_dict = response.data.financial_year_dict || {};
        const income_stream_dict = response.data.income_stream_dict || {};

        const statsRows = response.data.stats_rows || [];
        const ytdRows = response.data.ytd_rows || [];

        // Map Stats Rows into Table Format
        const formattedStatsRows = statsRows.map((row) => ({
          name: row.name,
          num_employees: row.num_employees,
          readable_last_pay_date: row.readable_last_pay_date || "None",
          readable_last_processed_date:
            row.readable_last_processed_date || "None",
          last_pay_run_completed_pays: row.last_pay_run_completed_pays,
          last_pay_run_pay_period: row.last_pay_run_pay_period || "None",
          last_stp_event: row.last_stp_event || "None",
          last_stp_event_pay_run: row.last_stp_event_pay_run || "None",
          last_stp_event_num_employees: row.last_stp_event_num_employees,
          last_stp_event_sent_status: row.last_stp_event_sent_status || "None",
          last_stp_event_type: row.last_stp_event_type || "None",
          current_fy_completed_pay_runs: row.current_fy_completed_pay_runs,
          num_portals_active: row.num_portals_active,
          total_holiday_leave: row.total_holiday_leave,
          total_sick_leave: row.total_sick_leave,
          total_lsl: row.total_lsl,
          total_custom_leave: row.total_custom_leave,
        }));

        // Map YTD Rows into Table Format
        const formattedYtdRows = ytdRows.map((row) => ({
          name: row.name,
          total_gross: row.total_gross,
          total_tax: row.total_tax,
          total_net: row.total_net,
          total_stsl: row.total_stsl,
          total_super: row.total_super,
          total_sgc: row.total_sgc,
          total_resc: row.total_resc,
        }));

        setFinancialYearDict(financial_year_dict);
        setIncomeStreamDict(income_stream_dict);

        setFinancialYear(Object.keys(financial_year_dict)[0]);
        setIncomeStream(Object.keys(income_stream_dict)[0]);

        setStatsRows(formattedStatsRows);
        setYTDRows(formattedYtdRows);
      }
    } catch (error) {
      console.error("Error getting company data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Define columns for Stats Table
  const statsColumns = [
    { label: "Name", accessor: "name", widget: "text", useAsKey: true },
    { label: "Num Employees", accessor: "num_employees", widget: "text" },
    {
      label: "Last Pay Date",
      accessor: "readable_last_pay_date",
      widget: "text",
    },
    {
      label: "Last Processed Date",
      accessor: "readable_last_processed_date",
      widget: "text",
    },
    {
      label: "Completed Pays",
      accessor: "last_pay_run_completed_pays",
      widget: "text",
    },
    {
      label: "Pay Period",
      accessor: "last_pay_run_pay_period",
      widget: "text",
    },
    { label: "Last STP Event", accessor: "last_stp_event", widget: "text" },
    {
      label: "STP Pay Run",
      accessor: "last_stp_event_pay_run",
      widget: "text",
    },
    {
      label: "STP Num Employees",
      accessor: "last_stp_event_num_employees",
      widget: "text",
    },
    {
      label: "STP Sent Status",
      accessor: "last_stp_event_sent_status",
      widget: "text",
    },
    {
      label: "STP Event Type",
      accessor: "last_stp_event_type",
      widget: "text",
    },
    {
      label: "FY Completed Pay Runs",
      accessor: "current_fy_completed_pay_runs",
      widget: "text",
    },
    {
      label: "Num Portals Active",
      accessor: "num_portals_active",
      widget: "text",
    },
    {
      label: "Total Holiday Leave",
      accessor: "total_holiday_leave",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total Sick Leave",
      accessor: "total_sick_leave",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total LSL",
      accessor: "total_lsl",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total Custom Leave",
      accessor: "total_custom_leave",
      widget: "MoneyLabel",
      align: "right",
    },
  ];

  // Define columns for YTD Table
  const ytdColumns = [
    { label: "Name", accessor: "name", widget: "text", useAsKey: true },
    {
      label: "Total Gross",
      accessor: "total_gross",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total Tax",
      accessor: "total_tax",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total Net",
      accessor: "total_net",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total STSL",
      accessor: "total_stsl",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total Super",
      accessor: "total_super",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total SGC",
      accessor: "total_sgc",
      widget: "MoneyLabel",
      align: "right",
    },
    {
      label: "Total RESC",
      accessor: "total_resc",
      widget: "MoneyLabel",
      align: "right",
    },
  ];

  // Callback function for API request when dropdowns change
  const recalculateYTDOverview = async (
    selectedFinancialYear,
    selectedIncomeStream
  ) => {
    setLoading(true);
    const url = `${adminContext.constants.BASE_URL}/company/${adminContext.company.id}/agent-dashboard?financial_year=${selectedFinancialYear}&income_stream=${selectedIncomeStream}`;

    try {
      const response = await adminContext.getRequest(url);
      if (isMounted.current) {
        // Extract stats_rows and ytd_rows from the response
        const recalculatedYTDRows = response.data.recalculated_ytd_rows || [];

        // Map YTD Rows into Table Format
        const formattedYtdRows = recalculatedYTDRows.map((row) => ({
          name: row.name,
          total_gross: row.total_gross,
          total_tax: row.total_tax,
          total_net: row.total_net,
          total_stsl: row.total_stsl,
          total_super: row.total_super,
          total_sgc: row.total_sgc,
          total_resc: row.total_resc,
        }));

        setYTDRows(formattedYtdRows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handlers for ComboBox changes
  const handleFinancialYearChange = (newValue) => {
    const financialYearKey = Object.keys(financialYearDict).find(
      (key) => financialYearDict[key] === newValue
    );
    setFinancialYear(financialYearKey);
    recalculateYTDOverview(financialYearKey, incomeStream);
  };

  const handleIncomeStreamChange = (newValue) => {
    const incomeStreamKey = Object.keys(incomeStreamDict).find(
      (key) => incomeStreamDict[key] === newValue
    );
    setIncomeStream(incomeStreamKey);
    recalculateYTDOverview(financialYear, incomeStreamKey);
  };

  return (
    <Card className="shadow-sm">
      <CardBody>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Row>
              <Col>
                <h1 className="mb-4 days-one text-center">Agent Dashboard</h1>
                <h4
                  className="mb-4 days-one text-center"
                  style={{ color: "#E67E22" }}
                >
                  This dashboard will only be available to users with an Agent
                  Licence after 10th January 2025.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableWidget
                  label="Multi-Company Overview"
                  columns={statsColumns}
                  rows={statsRows}
                  loading={loading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="mt-4 days-one">Year to Date Overview</h2>
                <h4>
                  <strong>
                    Select Financial Year & STP Income Stream To View
                  </strong>
                </h4>
                <div className="dashboard-combobox-grid">
                  <ComboBox
                    label="Financial Year"
                    comboData={financialYearDict}
                    selectedComboItem={financialYear}
                    setSelectedComboItem={handleFinancialYearChange}
                    sortCallback={(a, b) => b.localeCompare(a)}
                  />
                  <ComboBox
                    label="Income Stream"
                    comboData={incomeStreamDict}
                    selectedComboItem={incomeStream}
                    setSelectedComboItem={handleIncomeStreamChange}
                  />
                </div>
                <TableWidget
                  columns={ytdColumns}
                  rows={YTDRows}
                  loading={loading}
                  total={true}
                />
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default AgentDashboard;
