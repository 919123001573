import { AvField } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";

const MoneyEdit = (props) => {
  // Utility to format to two decimal places as a string
  const formatToCurrency = (value) => {
    const numericValue = Number(value);
    return isNaN(numericValue) ? "0.00" : numericValue.toFixed(2);
  };
  const [inputValue, setInputValue] = useState(
    props.amount?._decimal ? formatToCurrency(props.amount._decimal) : ""
  );

  const handleChange = (e) => {
    return;
  };

  const onFocus = (e) => {
    if (!e.target.value || Number(e.target.value) === 0) {
      setInputValue("");
    }
  };

  const onBlur = (e) => {
    const formattedValue = formatToCurrency(e.target.value || "0");
    setInputValue(formattedValue);

    // Update parent component via props
    props.handleChange?.({
      target: {
        name: e.target.name,
        value: { _decimal: formattedValue },
      },
    });

    // Call optional onBlur prop
    props.onBlur?.({
      target: {
        name: e.target.name,
        value: { _decimal: formattedValue },
      },
    });
  };

  useEffect(() => {
    // Update input value when props.amount changes
    if (props.amount?._decimal !== undefined) {
      setInputValue(formatToCurrency(props.amount._decimal));
    }
  }, [props.amount?._decimal]);

  const negativeStyle = inputValue.startsWith("-") ? "text-danger" : "";

  return (
    <AvField
      onChange={handleChange}
      onFocus={onFocus}
      type="text"
      onBlur={onBlur}
      className={negativeStyle}
      value={inputValue}
      name={props.name || "amount"}
      label={props.label ? `${props.label} ($)` : "Amount ($)"}
      validate={{
        required: {
          value: true,
          errorMessage: "Please enter an amount",
        },
        pattern: {
          value: "^-?\\d+(\\.\\d{1,2})?$",
          errorMessage: "Invalid amount. Must be in dollars and cents.",
        },
      }}
      disabled={props.disabled}
    />
  );
};

export default MoneyEdit;
