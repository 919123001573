import dayjs from "dayjs";
const updateEmployeeLists = ({
  rows,
  setRows,
  adminContext,
  form,
  selectedRows,
  setSelectedRows,
}) => {
  if (!adminContext) return;
  if (!rows || !rows.employees) adminContext.history.goBack();
  const availableEmployees = form.show_terminated
    ? adminContext.company.employees
    : rows.employees.filter((employee) => !employee.is_terminated);
  const filteredSelectedEmployees = selectedRows.employees.filter((employee) =>
    availableEmployees.includes(employee)
  );
  setSelectedRows((prevState) => ({
    ...prevState,
    employees: filteredSelectedEmployees,
  }));
  setRows((prevState) => ({
    ...prevState,
    employees: availableEmployees,
  }));
};

const downloadCSV = async (reportType, csvData, adminContext) => {
  const datetimeString = dayjs().format("YYYY-MM-DD_HH-mm-ss");
  const fileName = reportType + "_" + datetimeString + ".csv";
  await adminContext.handleFileDownload(fileName, csvData, "text/csv");
};

const downloadPDF = async (reportType, pdfData, adminContext) => {
  const datetimeString = dayjs().format("YYYY-MM-DD_HH-mm-ss");
  const fileName = reportType + "_" + datetimeString + ".pdf";
  await adminContext.handlePDFDownload(fileName, pdfData);
};

const processReport = (
  saveOnly,
  csvMode,
  launcherMode,
  adminContext,
  selectedRows,
  form,
  setForm,
  setPdfB64Data,
  setReactPdfDocument,
  openModal,
  reportType,
  setIsLoading
) => {
  setIsLoading(true);
  const selectedEmployees = selectedRows.employees.map((employee) => {
    return employee.id;
  });
  let selectedColumns;
  if (selectedRows.columns) {
    selectedColumns = selectedRows.columns.map((column) => {
      return column.id;
    });
  } else {
    selectedColumns = [];
  }

  const data = {
    ...form,
    employees: selectedEmployees,
    columns: selectedColumns,
  };
  let url =
    adminContext.constants.BASE_URL +
    `/reports/custom/launch/${reportType}/${adminContext.company.id}`;

  const params = new URLSearchParams();
  if (!saveOnly) {
    params.append(csvMode ? "csv_mode" : "pdf_mode", "true");
  } else {
    params.append("csv_mode", "false");
    params.append("pdf_mode", "false");
  }
  url += `?${params.toString()}`;

  adminContext.postRequest(url, data, (response) => {
    setIsLoading(false);
    if (response.data.error) {
      adminContext.setAlertColor("warning");
      adminContext.setAlertMessage(response.data.error);
      return;
    }
    if (!launcherMode && response.data.existing_reports) {
      setForm({
        ...form,
        existing_reports: response.data.existing_reports,
        selected_report_id: response.data.selected_report_id,
      });
    } else if (launcherMode) {
      setForm({
        ...form,
        selected_report_id: response.data.selected_report_id,
      });
    }

    if (!saveOnly && !csvMode) {
      if (response.data.pdf_file_data) {
        if (adminContext.checkIsNativePlatform()) {
          downloadPDF(
            form.report_name,
            response.data.pdf_file_data,
            adminContext
          );
        } else {
          setPdfB64Data(
            "data:application/pdf;base64," + response.data.pdf_file_data
          );
          setReactPdfDocument(
            <div id="report-preview">
              <iframe
                title="report-preview"
                width="100%"
                height="100%"
                src={
                  "data:application/pdf;base64," + response.data.pdf_file_data
                }
              />
            </div>
          );
          openModal();
        }
      }
    } else if (saveOnly) {
      adminContext.setAlertColor("success");
      adminContext.setAlertMessage("Report saved successfully");
    } else if (csvMode) {
      if (response.data.csv_file_data) {
        downloadCSV(
          form.report_name,
          response.data.csv_file_data,
          adminContext
        );
        adminContext.setAlertColor("success");
        adminContext.setAlertMessage("CSV generated successfully");
      } else {
        adminContext.setAlertColor("warning");
        adminContext.setAlertMessage("CSV could not be generated");
      }
    }
  });
};

const initReport = (
  adminContext,
  form,
  setForm,
  reportType,
  setIsLoading,
  rows,
  setRows,
  setSelectedRows,
  launchMode = false
) => {
  setIsLoading(true);
  let url;
  if (launchMode) {
    url =
      adminContext.constants.BASE_URL +
      `/reports/custom/launcher/init/` +
      adminContext.company.id +
      `?report_id=${form.selected_report_id}`;
  } else {
    url =
      adminContext.constants.BASE_URL +
      `/reports/custom/init/${reportType}/` +
      adminContext.company.id +
      (form.selected_report_id ? `?report_id=${form.selected_report_id}` : "");
  }

  // Download the data for the report, optionally using reportId
  adminContext.getRequest(url, (response) => {
    setIsLoading(false);

    if (response.data.error) {
      adminContext.setAlertColor("warning");
      adminContext.setAlertMessage(response.data.error);
      return;
    }

    if (response.data.form) {
      if (launchMode && (!form || !form.selected_report_id)) {
        setForm(response.data.form);
      } else {
        // Avoid triggering the useEffect on selected_report_id change
        setForm((prevForm) => ({
          ...prevForm, // Keep the current form values
          ...response.data.form, // Update with new form values
          selected_report_id: prevForm.selected_report_id, // Retain the original value of selected_report_id
        }));
      }
      if (launchMode) {
        // Launch Mode does not change columns, only the builder edits these.
        setRows({
          employees: response.data.form.employees,
        });
        setSelectedRows({
          employees: response.data.form.employees,
        });
      } else {
        setRows({
          employees: response.data.form.employees,
          columns: response.data.form.columns,
        });
        setSelectedRows({
          employees: response.data.form.employees,
          columns: response.data.form.selected_columns,
        });
      }
    }
  });
};

export { updateEmployeeLists, processReport, initReport };
