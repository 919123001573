import { useEffect, useContext, useState } from "react";
import { Card, CardBody } from "reactstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

const RemoteValidationView = ({
  wizardInfo,
  setWizardInfo,
  loading,
  setLoading,
  handleNext,
}) => {
  const adminContext = useContext(AdminContext);

  const [rows, setRows] = useState([]);

  const init = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/super-fund-deposits/remote-validation`,
      wizardInfo,
      (response) => {
        if (response.data.wizard_info) {
          setWizardInfo(response.data.wizard_info);
        }
        if (response.data.wizard_info.validation_message === null) {
          handleNext();
        }
        if (response.data.wizard_info.validation_items) {
          setRows(response.data.wizard_info.validation_items);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-left">
            <Typography variant="h4" align="center" gutterBottom>
              Remote Validation
            </Typography>
            <Typography variant="body1" paragraph>
              Remote validation is the final step for validating the SuperStream
              Contribution or Registration Messages that will be sent via the
              SuperStream network. This step contacts our servers for a robust
              set of error checking.
            </Typography>
            {wizardInfo.validation_message && (
              <h4>Validation message: {wizardInfo.validation_message}</h4>
            )}

            {rows.length > 0 ? (
              <TableContainer className="my-3" component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Error Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No validation errors found.
              </Typography>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default RemoteValidationView;
