import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { useContext } from "react";
import AdminContext from "../../AdminContext";
const UserRestricted = () => {
  const adminContext = useContext(AdminContext);

  return (
    <Container
      maxWidth="sm"
      style={{ marginTop: "20vh", textAlign: "center", minHeight: "50vh" }}
    >
      <Typography variant="h4" gutterBottom>
        Access Restricted
      </Typography>
      {!adminContext.company.is_locked ? (
        <Typography variant="body1" paragraph>
          Sorry, your user profile does not have permission to view this page.
          If you believe you need access, please{" "}
          <a
            className="underline-link"
            href={"mailto:" + adminContext.primarySubscriptionEmail}
          >
            contact
          </a>{" "}
          your payroll administrator.
        </Typography>
      ) : (
        <Typography variant="body1" paragraph>
          Sorry, your company is in a locked state. You cannot perform this
          action. {" "}
          <a
            className="underline-link"
            href={"https://www.lightningpayroll.com.au/faq?selected=318"}
            target="_blank"
          >
             Click here for more information.
          </a>
        </Typography>
      )}

      <Button variant="contained" color="primary" href="/admin/entities/select">
        Go Home
      </Button>
    </Container>
  );
};

export default UserRestricted;
