import { useState, useContext, useEffect } from "react";
import BackButton from "../../components/Widgets/BackButton";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import ComboBox from "../../components/Widgets/ComboBox";
import validateABN from "../../validators/validateABN";
import AdminContext from "../../AdminContext";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
} from "reactstrap";
import LoadingSpinner from "components/Widgets/LoadingSpinner";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CheckBox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import InfoLabel from "components/Widgets/InfoLabel";

const SupplierInformation = () => {
  const [isLoading, setIsLoading] = useState(false);
  // Cookies are required for authentication
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [supplierInfo, setSupplierInfo] = useState({});
  const adminContext = useContext(AdminContext);
  const [stpRole, setStpRole] = useState({
    submit_as: adminContext.appConfig.submit_as_business_intermediary.value
      ? "business_intermediary"
      : "registered_agent",
  });

  // Update the backend if the submit_as_supplier value changes
  useEffect(() => {
    // Only update the backend if it has changed
    if (stpRole.submit_as === "business_intermediary") {
      if (!adminContext.appConfig.submit_as_business_intermediary.value) {
        adminContext.saveAppConfigChanges(
          "submit_as_business_intermediary",
          true
        );
      }
    } else if (adminContext.appConfig.submit_as_business_intermediary.value) {
      adminContext.saveAppConfigChanges(
        "submit_as_business_intermediary",
        false
      );
    }
  }, [stpRole.submit_as]);

  useEffect(() => {
    const init = () => {
      setIsLoading(true);
      adminContext.getRequest(
        adminContext.constants.BASE_URL + `/settings/supplier-info`,
        (response) => {
          if (response.data.supplier_info) {
            setSupplierInfo(response.data.supplier_info);
          }
          setIsLoading(false);
        }
      );
    };
    init();
  }, []);

  // Update the form on data change
  const handleChange = (event) => {
    setSupplierInfo({
      ...supplierInfo,
      [event.target.name]: event.target.value,
    });
    let ABNValid = validateABN(supplierInfo.abn);

    if (!ABNValid) {
      showError("Invalid ABN");
    }
  };

  // Update the API on submit
  const saveChanges = (e) => {
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/settings/supplier-info`,
      supplierInfo,
      (response) => {
        if (response.status === 200) {
          adminContext.setAlertMessage("Changes saved successfully");
        }
      }
    );
  };
  const submitStpSupplierDeclaration = () => {
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        `/settings/submit-supplier-stp-declaration`,
      {},
      (response) => {
        adminContext.setAlertMessage("STP Declaration submitted successfully");
        if (response.data.supplier_info) {
          setSupplierInfo(response.data.supplier_info);
        }
      }
    );
  };

  
  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  if (isLoading || !supplierInfo) {
    return <LoadingSpinner />;
  }

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      <Container className="mt-4" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <h1 className="days-one text-center">Supplier Information</h1>
            {!supplierInfo.id && <BackButton />}

            <SaveChangesMessageRow
              errorMessage={errorMessage}
              successMessage={successMessage}
              saveChanges={saveChanges}
            />
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form key={supplierInfo.id}>
                  <h3 className="text-muted mb-4">
                    Supplier Information (for Agents or Business Intermediaries)
                  </h3>
                  <p>
                    Below is where you can enter supplier information for all
                    companies within this database. This is used in the Single
                    Touch Payroll section if you are submitting on the behalf of
                    an employer.
                  </p>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={supplierInfo.name}
                            id="input-name"
                            placeholder="Enter name here"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-abn"
                          >
                            ABN
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-abn"
                            placeholder="Enter abn here"
                            value={supplierInfo.abn}
                            name="abn"
                            onChange={handleChange}
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-tax_agent_number"
                          >
                            Tax Agent Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-tax_agent_number"
                            placeholder="Enter registered agent number if required"
                            value={supplierInfo.tax_agent_number}
                            name="tax_agent_number"
                            onChange={handleChange}
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Enter email here"
                            value={supplierInfo.email}
                            name="email"
                            onChange={handleChange}
                            type="email"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Enter phone here"
                            value={supplierInfo.phone}
                            name="phone"
                            onChange={handleChange}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Address information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address1"
                          >
                            Address line 1
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={supplierInfo.address1}
                            onChange={handleChange}
                            name="address1"
                            id="input-address1"
                            placeholder="Address line 1"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address2"
                          >
                            Address line 2
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={supplierInfo.address2}
                            id="input-address2"
                            name="address2"
                            onChange={handleChange}
                            placeholder="Address line 2"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-city"
                            placeholder="Enter city here"
                            value={supplierInfo.city}
                            name="city"
                            onChange={handleChange}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <div>
                          <ComboBox
                            disabled={!adminContext.editAccess("settings")}
                            label="State"
                            stretch={true}
                            className="mx-3"
                            name="state"
                            onChange={handleChange}
                            comboData={adminContext.constants.STATES}
                            selectedComboItem={supplierInfo.state}
                            setSelectedComboItem={(data) =>
                              setSupplierInfo({ ...supplierInfo, state: data })
                            }
                          />
                        </div>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-postcode"
                          >
                            Postcode
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-postcode"
                            placeholder="Enter postcode here"
                            value={supplierInfo.postcode}
                            name="postcode"
                            onChange={handleChange}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
                <hr />
                <Row>
                  <Col>
                    <h3>STP Role Control</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormControlLabel
                      label="Submit STP as a third party (business intermediary) or tax agent?"
                      control={
                        <CheckBox
                          checked={
                            adminContext.appConfig.submit_as_supplier.value
                          }
                          color="primary"
                          id="mastercheck"
                          onChange={() => {
                            adminContext.saveAppConfigChanges(
                              "submit_as_supplier",
                              !adminContext.appConfig.submit_as_supplier.value
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>

                {adminContext.appConfig.submit_as_supplier.value && (
                  <Row>
                    <Col>
                      {" "}
                      <div className="mx-2 my-1">
                        <InfoLabel
                          label="Submit as"
                          infoText={
                            <>
                              Click{" "}
                              <a
                                href="https://www.lightningpayroll.com.au/faq?selected=179"
                                target="_blank"
                              >
                                here
                              </a>{" "}
                              to learn more about these options.
                            </>
                          }
                        />
                      </div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={stpRole.submit_as}
                          onChange={(event) => {
                            setStpRole({
                              ...stpRole,
                              submit_as: event.target.value,
                            });
                          }}
                        >
                          <FormControlLabel
                            key="registered_agent"
                            value="registered_agent"
                            control={<Radio />}
                            label="Submit as a Registered Agent"
                          />
                          <FormControlLabel
                            key="business_intermediary"
                            value="business_intermediary"
                            control={<Radio />}
                            label="Submit as a Business Intermediary"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                  </Row>
                )}
                {adminContext.appConfig.submit_as_supplier.value &&
                  !supplierInfo.declaration_indicator && (
                    <div class="my-3">
                      <hr />
                      <Row>
                        <Col>
                          <h3>Manual STP Declaration (API users only)</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            To send Single Touch Payroll for the above supplier
                            information, you will need to agree to the following
                            declaration, as required by the Australian Taxation
                            Office.
                          </p>
                          <p>
                            <strong>
                              'I declare the information transmitted in these
                              STP payroll reports is true and correct and I am
                              authorised to make this declaration.'
                            </strong>
                          </p>
                          <p>
                            Please click 'Yes' to agree, thereby signing this
                            declaration.
                          </p>
                          <Button onClick={submitStpSupplierDeclaration}>
                            Yes
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </fieldset>
  );
};
export default SupplierInformation;
