import React, { useState, useEffect } from "react";
import ListWidget from "./ListWidget"; // Adjust the import path according to your file structure

import { FormControlLabel, Checkbox } from "@mui/material";

const EmployeeListWidget = (props) => {
  const [showTerminated, setShowTerminated] = useState(false);

  const filterTerminatedEmployees = (row) => {
    return showTerminated || !row.is_terminated;
  };

  const toggleShowTerminated = () => {
    setShowTerminated((prevShowTerminated) => !prevShowTerminated);
  };

  useEffect(() => {
    if (!showTerminated) {
      // Remove terminated employees from selectedRows

      const newSelectedRows = (props.selectedRows[props.name] || []).filter(
        (row) => !row.is_terminated
      );

      props.setSelectedRows({
        ...props.selectedRows,
        [props.name]: newSelectedRows,
      });
    }
  }, [showTerminated]);

  return (
    <>
      {!props.hideShowTerminated && (
        <FormControlLabel
          control={
            <Checkbox
              checked={showTerminated}
              onChange={toggleShowTerminated}
              color="primary"
            />
          }
          label="Show terminated?"
        />
      )}

      <ListWidget {...props} filterCallback={filterTerminatedEmployees} />
    </>
  );
};

export default EmployeeListWidget;
