import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Row, Col } from "reactstrap";
import dayjs from "dayjs";

const DateEdit = (props) => {
  const {
    label,
    value,
    onChange,
    className,
    minDate,
    maxDate,
    disabled,
    stretch,
    align,
  } = props;

  const [selectedDate, setSelectedDate] = useState(
    value ? new Date(value) : new Date()
  );
  const [lastValidDate, setLastValidDate] = useState(
    value && !isNaN(new Date(value).getTime()) ? new Date(value) : new Date()
  );

  // Update the state when the value prop changes
  useEffect(() => {
    const newDate = value ? new Date(value) : new Date();
    if (!isNaN(newDate.getTime())) {
      setSelectedDate(newDate);
      setLastValidDate(newDate);
    }
  }, [value]);

  const handleChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      const isoDateString = dayjs(date).format("YYYY-MM-DD");
      setSelectedDate(date);
      setLastValidDate(date);
      onChange(isoDateString);
    } else {
      setSelectedDate(null);
    }
  };

  const handleBlur = () => {
    if (!selectedDate || isNaN(selectedDate.getTime())) {
      setSelectedDate(lastValidDate);
    }
  };

  const customDateParser = (dateString) => {
    const parts = dateString.split("/");
    if (parts.length === 3) {
      if (parts[2].length === 2) {
        parts[2] = "20" + parts[2];
      }
      const date = dayjs(parts.join("/"), "DD/MM/YYYY", true);
      return date.isValid() ? date.toDate() : null;
    }
    return null;
  };

  const datePickerClass = `${className || "mui-style-input bg-white"} ${
    stretch ? "width-100" : ""
  } ${disabled ? "widget-disabled" : ""}`;

  const wrapperStyle = stretch ? { width: "100%" } : {};

  const inputStyle = {
    textAlign: align,
    backgroundColor: disabled ? "#e6e6e6" : "",
  };

  return (
    <Row className="mb-4">
      <Col className="mx-auto">
        <div className="my-0 py-0 form-group">
          <label className="my-0 pb-2 form-label font-heavy">{label}</label>
        </div>

        <div style={wrapperStyle}>
          <DatePicker
            className={datePickerClass}
            selected={selectedDate}
            onChange={handleChange}
            onBlur={handleBlur}
            dateFormat={["dd/MM/yyyy", "dd/MM/yy"]} // Accept both formats
            parseDate={customDateParser} // Custom date parser
            strictParsing={false}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            disabled={disabled ? disabled : false}
            customInput={<input style={inputStyle} />}
          />
        </div>
      </Col>
    </Row>
  );
};

export default DateEdit;
