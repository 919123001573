import { useEffect, useState, useContext } from "react";
import TableWidget from "../../components/Widgets/TableWidget";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import { SuperFundProviderFormSpec } from "../../variables/ValidationFormSpecs";
import AdminContext from "../../AdminContext";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import validateABN from "../../validators/validateABN";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "reactstrap";

const SuperFundProviders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [selectedSuperFund, setSelectedSuperFund] = useState(null);
  const [pdfB64Data, setPdfB64Data] = useState("");
  const adminContext = useContext(AdminContext);

  useEffect(() => {
    const init = () => {
      setIsLoading(true);
      adminContext.getRequest(
        adminContext.constants.BASE_URL + `/settings/super-fund-providers`,
        (response) => {
          setIsLoading(false);
        }
      );
    };
    init();
  }, []);

  const saveSuperFund = (superFund) => {
    setSaving(false);
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/settings/super-fund`,
      superFund,
      (response) => {
        setIsLoading(false);
      }
    );
  };

  const columns = [
    {
      label: "Name",
      accessor: "name",
      widget: "Text",
    },
    {
      label: "Type",
      accessor: "provider_type_friendly",
      widget: "Text",
    },
    {
      label: "Active?",
      accessor: "is_active",
      widget: "BooleanLabel",
    },
    {
      label: "ABN",
      accessor: "abn",
      widget: "Text",
    },
    {
      label: "USI",
      accessor: "usi",
      widget: "Text",
    },
    {
      label: "Electronic Address",
      accessor: "eaddress",
      widget: "Text",
    },
    {
      label: "Payment Method",
      accessor: "payment_method",
      widget: "Text",
    },
    {
      label: "BSB",
      accessor: "bsb",
      widget: "Text",
    },
    {
      label: "Account Number",
      accessor: "account_number",
      widget: "Text",
    },
    {
      label: "Account Name",
      accessor: "account_name",
      widget: "Text",
    },
  ];


  // If selected super fund changes log its provider_type 
  useEffect(() => {
    if (selectedSuperFund) {
      console.log(selectedSuperFund.provider_type);
    }
  }, [selectedSuperFund]);

  const updateSuperFund = (superFund) => {
    setSaving(true);
    saveSuperFund(superFund);
    setSelectedSuperFund(null);
  };

  const deleteSuperFund = (idToDelete) => {
    setSaving(true);
    adminContext.deleteRequest(
      adminContext.constants.BASE_URL +
        `/settings/super-fund-providers/${idToDelete}`,
      (response) => {
        setSaving(false);
      }
    );
  };

  const downloadSuperFundProvidersPdf = () => {
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/settings/super-fund-providers/pdf`,
      (response) => {
        setPdfB64Data(
          "data:application/pdf;base64," + response.data.pdf_file_data
        );

        const downloadLink = document.createElement("a");
        const fileName = "Lightning Payroll Super Fund Providers.pdf";
        downloadLink.href = pdfB64Data;
        downloadLink.download = fileName;
        downloadLink.click();
        setIsLoading(false);
      }
    );
  };

  const filteredProviders = () => {
    if (showInactive) {
      return adminContext.superFundProviders;
    }
    return adminContext.superFundProviders.filter(
      (provider) => provider.is_active
    );
  };

  const formSpec = SuperFundProviderFormSpec();

  return (
    <fieldset disabled={!adminContext.editAccess("settings")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">
                      Super Fund Provider Information
                    </h1>
                  </Col>
                </Row>
                {!saving ? (
                  <>
                    {!selectedSuperFund && (
                      <>
                        <Row>
                          <Col>
                            {" "}
                            <FormControlLabel
                              control={
                                <CheckBox
                                  color="primary"
                                  className="mx-2"
                                  name="active_pay_recipient"
                                  checked={showInactive}
                                  onChange={(e) => {
                                    setShowInactive(e.target.checked);
                                  }}
                                />
                              }
                              label={"Show Inactive Super Funds?"}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex justify-content-center mx-auto">
                            <Button
                              color="primary"
                              className="btn btn-lg width-80-on-lg text-center min-height-80 semi-active-button my-2"
                              onClick={() => {
                                adminContext.history.push(
                                  "/admin/create_fund_online_search"
                                );
                              }}
                            >
                              <p>
                                <i className="fa-regular fa-2xl fa-circle-plus"></i>
                              </p>
                              New Super Fund <strong>(Online Search)</strong>
                            </Button>
                          </Col>
                          <Col className="d-flex justify-content-center">
                            <Button
                              color="primary"
                              className="btn btn-lg width-80-on-lg text-center min-height-80 semi-active-button my-2"
                              onClick={downloadSuperFundProvidersPdf}
                            >
                              <p>
                                <i className="fa-regular fa-2xl fa-print"></i>
                              </p>
                              Print/Export Super Funds
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col>
                        <>
                          <TableWidget
                            rows={filteredProviders()}
                            columns={columns}
                            addNewLabel={
                              "Add New Super Fund (Manual entry/SMSF)"
                            }
                            deleteRowCallbackEnabled={(fund) =>
                              fund.delete_enabled
                            }
                            rowStyleCallback={(row) =>
                              row.is_active ? "" : "bg-warning"
                            }
                            editRowSaveCallback={updateSuperFund}
                            deleteRowCallback={deleteSuperFund}
                            deleteConfirmationAttributes={["usi", "name"]}
                            formSpec={formSpec}
                            setSelected={setSelectedSuperFund}
                            appendRowCallback={() => {
                              return {
                                id: null,
                                name: "",
                                payment_method: "DirectCredit",
                                provider_type: "SMSF",
                                is_active: true,
                                abn: "",
                                usi: "",
                              };
                            }}
                          />
                        </>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default SuperFundProviders;
