// reactstrap components
import { Button, Alert } from "reactstrap";
import React, { useState, useContext, useEffect } from "react";
import AdminContext from "../../AdminContext";
import BackButton from "../../components/Widgets/BackButton";
import QuestionModal from "../../components/Widgets/QuestionModal";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import TrialRestricted from "../../components/Widgets/TrialRestricted";
import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import TableWidget from "../../components/Widgets/TableWidget";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";

const OnlineBackupsView = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const [backups, setBackups] = useState([]);
  const adminContext = useContext(AdminContext);
  const [computerName, setComputerName] = useState(
    adminContext.constants.DEFAULT_COMPUTER_NAME
  );
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
    reset_code: "",
  });

  const timeOutText = () => {
    setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 10000);
  };

  const onlineBackupsLogin = () => {
    // Attempt login to the online backups server, return a list of backups if so, otherwise display login form.
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/tools/online-backups/login",
      formData,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.backup_info && response.data.mode === "") {
            setLoggedIn(true);
            setBackups(response.data.backup_info);
            setErrorMessage("");
            setSuccessMessage(
              "You have successfully logged into the online backups server"
            );
            setShowLogin(false);
            setShowRegistration(false);
          } else if (response.data.mode === "login") {
            setErrorMessage("");
            setSuccessMessage("");
            setShowLogin(true);
            setShowRegistration(false);
          } else if (response.data.mode === "registration") {
            setErrorMessage("");
            setSuccessMessage("");
            setShowLogin(false);
            setShowRegistration(true);
          }
          if (response.data.error) {
            setErrorMessage(response.data.error);
            setSuccessMessage("");
          } else {
            setErrorMessage("");
            setSuccessMessage("");
          }
          timeOutText();
        }
      }
    );
  };

  const resetPassword = () => {
    // Sends a password reset request email to the user
    setIsLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        "/tools/online-backups/password-reset-request",
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.error) {
            setErrorMessage(response.data.error);
            setSuccessMessage("");
          } else {
            setErrorMessage("");
            setSuccessMessage("Password reset request sent.");
            setShowRegistration(true);
            setShowLogin(false);
            setShowReset(true);
          }
          timeOutText();
        }
      }
    );
  };

  const onlineBackupsRegister = () => {
    // Register a new online backups account
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/tools/online-backups/register",
      formData,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.error) {
            setErrorMessage(response.data.error);
            setSuccessMessage("");
          } else {
            setErrorMessage("");
            setSuccessMessage("Account created successfully.");
            onlineBackupsLogin();
          }
          timeOutText();
        }
      }
    );
  };

  const submitPasswordReset = () => {
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/tools/online-backups/password-reset",
      formData,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.error) {
            setErrorMessage(response.data.error);
            setSuccessMessage("");
          } else {
            setErrorMessage("");
            setSuccessMessage("Password was reset successfully.");
            onlineBackupsLogin();
          }
          timeOutText();
        }
      }
    );
  };

  const sendBackup = (backup) => {
    // Send a backup to the online backups server
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/tools/online-backups/send",
      { computer_name: computerName },
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.error) {
            setErrorMessage(response.data.error);
            setSuccessMessage("");
          } else {
            setErrorMessage("");
            setSuccessMessage("Backup sent successfully.");
            onlineBackupsLogin();
          }
          timeOutText();
        }
      }
    );
  };

  const restoreBackup = (backup) => {
    // Restore a backup from the online backups server

    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/tools/online-backups/restore",
      {
        id: backup.id,
        size: backup.size,
        created: backup.created,
        computer_id: backup.computer_id,
      },
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          adminContext.history.push("/admin/entities/select");
        }
      }
    );
  };

  const logout = () => {
    setLoggedIn(false);
    // Logout of the online backups server
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/tools/online-backups/logout",
      {},
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.error) {
            setErrorMessage(response.data.error);
            setSuccessMessage("");
          } else {
            setErrorMessage("");
            setSuccessMessage("You have successfully logged out.");
            onlineBackupsLogin();
          }
          timeOutText();
        }
      }
    );
  };

  useEffect(() => {
    onlineBackupsLogin();
  }, []);

  const saveChanges = (key, val) => {
    setIsLoading(true);

    // This variable will be true when the component is still mounted.
    let isMounted = true;

    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/tools/app-config-item`,
      { key: key, value: val },
      (response) => {
        // Only update state if the component is still mounted.
        if (isMounted) {
          setIsLoading(false);
          setSuccessMessage("Changes saved successfully.");
          setTimeout(() => {
            // Also, only update state if the component is still mounted.
            if (isMounted) {
              setSuccessMessage("");
            }
          }, 3000);
        }
      }
    );

    // Cleanup function that will be run when the component unmounts.
    return () => {
      isMounted = false;
    };
  };

  // Update on data change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (adminContext.isTrial) {
    return <TrialRestricted screen="Online Backups" />;
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="mx-5">
          <div className="text-center mx-4 mt-2 mb-3">
            <h1 className="days-one">Online Backups</h1>
            {errorMessage && (
              <Alert color="warning">
                <strong>{errorMessage}</strong>
              </Alert>
            )}
            {successMessage && (
              <Alert color="success">
                <strong>{successMessage}</strong>
              </Alert>
            )}
          </div>
          <BackButton />

          {!loggedIn ? (
            showLogin ? (
              <>
                <AvForm
                  className="mx-auto width-80-on-lg"
                  onValidSubmit={onlineBackupsLogin}
                >
                  <p>
                    Please enter your online backups password below to login.
                  </p>
                  <AvField
                    label="Password"
                    onChange={handleChange}
                    name="password"
                    type="password"
                    value={formData.password}
                  />

                  <Button
                    className="btn-icon btn-3"
                    color="primary"
                    type="submit"
                  >
                    Login
                  </Button>
                  <Button
                    className="btn-icon btn-3"
                    color="secondary"
                    onClick={resetPassword}
                  >
                    Forgot Password?
                  </Button>
                </AvForm>
              </>
            ) : showRegistration ? (
              <>
                <AvForm
                  className="mx-auto width-80-on-lg"
                  onValidSubmit={
                    !showReset ? onlineBackupsRegister : submitPasswordReset
                  }
                >
                  {" "}
                  {showReset ? (
                    <>
                      <h2>Reset Password</h2>
                      <p>
                        Please enter your new password and the reset code which
                        has been emailed to your registered email address.
                      </p>
                    </>
                  ) : (
                    <>
                      <h2>No Online Backups Account Exists</h2>
                      <p>
                        Please{" "}
                        <span className="font-weight-bold">
                          create a password
                        </span>{" "}
                        to create a new online backups account. It must contain:
                      </p>
                    </>
                  )}
                  <ul className="text-muted">
                    <li>At least 8 characters</li>
                    <li>At least 1 uppercase letter</li>
                    <li>At least 1 lowercase letter</li>
                    <li>At least 1 number</li>
                    <li>At least 1 special character</li>
                  </ul>
                  <AvField
                    label="New Password"
                    onChange={handleChange}
                    name="password"
                    type="password"
                    value={formData.password}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Password is required",
                      },
                      pattern: {
                        value:
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                        errorMessage:
                          "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character",
                      },
                    }}
                  />
                  <AvField
                    label="Confirm Password"
                    onChange={handleChange}
                    name="confirm_password"
                    type="password"
                    value={formData.confirm_password}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Password confirmation is required",
                      },
                      match: {
                        value: "password",
                        errorMessage: "Passwords do not match",
                      },
                    }}
                  />
                  {showReset ? (
                    <>
                      <AvField
                        label="Reset Code"
                        onChange={handleChange}
                        name="reset_code"
                        type="text"
                        value={formData.reset_code}
                      />
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="btn-icon btn-3"
                      color="primary"
                      type="submit"
                    >
                      Register
                    </Button>
                  )}
                </AvForm>
              </>
            ) : null
          ) : (
            <>
              <p>
                Create or restore a backup file using our secure cloud server.
              </p>
              <AvForm>
                <AvField
                  value={computerName}
                  onChange={(e) => setComputerName(e.target.value)}
                  type="text"
                  name="computer_name"
                  label="Computer Name"
                />
              </AvForm>
              <Button
                className="btn-icon btn-3"
                color="primary"
                onClick={sendBackup}
              >
                Send Backup
              </Button>
            </>
          )}

          {backups && loggedIn ? (
            <>
              <FormControlLabel
                label="Automatically backup every 7 days?"
                control={
                  <CheckBox
                    checked={adminContext.appConfig.automatic_on.value}
                    color="primary"
                    id="automatic_on"
                    onChange={() => {
                      saveChanges(
                        "automatic_on",
                        !adminContext.appConfig.automatic_on.value
                      );
                    }}
                  />
                }
              />
              <FormControlLabel
                label="Prompt when a more recent backup is available?"
                control={
                  <CheckBox
                    checked={adminContext.appConfig.prompt_more_recent.value}
                    color="primary"
                    id="prompt_more_recent"
                    onChange={() => {
                      saveChanges(
                        "prompt_more_recent",
                        !adminContext.appConfig.prompt_more_recent.value
                      );
                    }}
                  />
                }
              />
              <TableWidget
                rows={backups}
                columns={[
                  {
                    label: "Created",
                    accessor: "created",
                    widget: "DateTimeLabel",
                  },
                  {
                    label: "Computer ID",
                    accessor: "computer_id",
                    widget: "Text",
                  },
                  {
                    label: "Backup File Size",
                    accessor: "size",
                    widget: "FileSizeLabel",
                    align: "right",
                  },
                  {
                    label: "Restore",
                    accessor: "restore",
                    widget: "Button",
                    align: "right",
                    args: {
                      buttonIcon: "fas fa-folder-open",
                      onClickCallback: (backup) => restoreBackup(backup),
                    },
                  },
                ]}
              />
              <Button
                className="btn-icon btn-3"
                color="warning"
                onClick={() => setLogoutConfirmationOpen(true)}
              >
                Logout
              </Button>
              <QuestionModal
                isOpen={logoutConfirmationOpen}
                title=""
                content={
                  <p>
                    Are you sure you would like to logout? Please be aware that
                    logging out will disable automatic online backups.
                  </p>
                }
                onConfirm={logout}
                onDeny={() => {
                  setLogoutConfirmationOpen(false);
                }}
              />
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default OnlineBackupsView;
