import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import dayjs from "dayjs";
import DateEdit from "../../components/Widgets/DateEdit";
import EmployeesSelect from "../../components/Widgets/EmployeesSelect";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";

const ReinstateEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const adminContext = useContext(AdminContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    // When component mounts, it's mounted
    setIsMounted(true);

    // Cleanup function to set mounted to false when component unmounts
    return () => setIsMounted(false);
  }, []);

  // Update the API on submit
  const saveChanges = () => {
    if (!isMounted) return;
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/employees/reinstate`,
      { start_date: startDate, employee_id: adminContext.currentEmployee.id },
      (response) => {
        if (isMounted) {
          // Check again before setting state
          setIsLoading(false);
          console.log(response.data);
          if (response.data.success) {
            if (response.data.employee) {
              adminContext.setCurrentEmployee(response.data.employee);
              adminContext.history.goBack();
            }
          } else {
            if (response.data.message) {
              setErrorMessage(response.data.message);
              setSuccessMessage("");
            } else {
              setErrorMessage("Something went wrong. Unable to save changes.");
              setSuccessMessage("");
            }
          }

          setTimeout(() => {
            setErrorMessage("");
            setSuccessMessage("");
          }, 5000);
        }
      }
    );
  };

  const getEmployeeName = () => {
    return (
      adminContext.currentEmployee.first_name +
      " " +
      adminContext.currentEmployee.last_name
    );
  };

  const handleDateChange = (dateTime) => {
    if (!dayjs(dateTime).isValid()) {
      setErrorMessage("New start date is not valid");
      return;
    } else {
      setErrorMessage("");
    }
    setStartDate(dateTime);
  };

  return (
    <>
      <Container className="mt-4" fluid>
        {adminContext.currentEmployee.id ? (
          <>
            <Card className="bg-secondary shadow">
              <CardBody>
                {!isLoading ? (
                  <>
                    <Row>
                      <Col>
                        <h1 className="days-one">Reinstate Employee</h1>
                        <p>
                          Please enter the new start date for{" "}
                          {getEmployeeName()}
                        </p>{" "}
                      </Col>{" "}
                    </Row>
                    <Row>
                      <Col>
                        <DateEdit
                          label="Start Date"
                          value={new Date()}
                          disabled={
                            adminContext.currentEmployee.locked ||
                            !adminContext.editAccess("employees")
                          }
                          onChange={(date) => handleDateChange(date)}
                          type="date"
                          name="start_date"
                        />
                      </Col>
                    </Row>
                    <SaveChangesMessageRow
                      disabledCallback={() =>
                        adminContext.currentEmployee.locked || errorMessage
                      }
                      errorMessage={errorMessage}
                      successMessage={successMessage}
                      saveChanges={saveChanges}
                    />
                  </>
                ) : (
                  <LoadingSpinner />
                )}
              </CardBody>
            </Card>
          </>
        ) : (
          <Container>
            <Row>
              <Col>
                <EmployeesSelect
                  className="text-left float-left"
                  title="Please select an employee"
                />
              </Col>
              <Col></Col>
            </Row>
          </Container>
        )}
      </Container>
    </>
  );
};
export default ReinstateEmployee;
