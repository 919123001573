import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  Input,
} from "reactstrap";
import { useEffect, useState, useContext } from "react";
import ListWidget from "../../../components/Widgets/ListWidget.js";
import ComboBox from "../../../components/Widgets/ComboBox.js";
import MoneyEdit from "../../../components/Widgets/MoneyEdit";
import moment from "moment";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";

import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FractionalDaysEdit from "../../../components/Widgets/FractionalDaysEdit";
import HoursEdit from "../../../components/Widgets/HoursEdit";
import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";

const PayRatesTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [hasBaseOrdinaryRate, setHasBaseOrdinaryRate] = useState(
    () => adminContext.currentEmployee.company_pay_rate_id !== null
  );
  const [rows, setRows] = useState({
    pay_rate_groups: [],
  });
  const [selectedRows, setSelectedRows] = useState({
    pay_rate_groups: [],
  });

  const [comboData, setComboData] = useState({
    pay_method: {
      CASH: "Cash",
      "DIRECT BANK ENTRY": "Direct Bank Entry",
      OTHER: "Other",
    },
    pay_type: { SALARY: "Salary", WAGE: "Wage" },
    employment_tenure: {},
    stp_employment_status: adminContext.constants.STP_EMPLOYMENT_STATUS,
    employment_type: {},
    award: {},
    base_ordinary_rate: {},
    classification_select: {},
    pay_period: adminContext.constants.PAY_PERIODS,
  });

  const initCombos = (responseData) => {
    setComboData({
      ...comboData,
      employment_tenure: responseData.employment_tenure,
      employment_type: responseData.employment_type,
      award: responseData.award,
      base_ordinary_rate: responseData.base_ordinary_rate,
      classification_select: responseData.classification_select,
    });
  };

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/employees/${adminContext.currentEmployee.id}/init`,
      (response) => {
        setRows({
          pay_rate_groups: response.data.pay_rate_groups,
        });
        setSelectedRows({
          pay_rate_groups: response.data.selected_pay_rate_groups,
        });
        initCombos(response.data);
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    initView();
  }, [adminContext.currentEmployee.id]);

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.checked,
      });
    } else if (event.target.type === "date") {
      const newDate = moment(new Date(event.target.value)).format("YYYY-MM-DD");
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: newDate,
      });
    } else {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        [event.target.name]: event.target.value,
      });
    }
  };

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      {
        ...adminContext.currentEmployee,
        pay_rate_groups: selectedRows.pay_rate_groups,
      },
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const periodsPerYear = () => {
    if (adminContext.currentEmployee.pay_period === "WEEKLY") {
      return 52;
    } else if (adminContext.currentEmployee.pay_period === "FORTNIGHTLY") {
      return 26;
    } else {
      return 12;
    }
  };

  const payPeriodDays = () => {
    if (adminContext.currentEmployee.pay_period === "WEEKLY") {
      return 5;
    } else if (adminContext.currentEmployee.pay_period === "FORTNIGHTLY") {
      return 10;
    } else {
      return (5 * 4 * 13) / 12;
    }
  };

  const hoursPerDay = () => {
    return (
      adminContext.currentEmployee.award_hours_day /
      adminContext.constants.MILLISECONDS_PER_HOUR
    );
  };

  const daysPerWeek = () => {
    return adminContext.currentEmployee.award_days_week / 1000 / 60 / 60 / 24;
  };

  const awardHoursPerWeek = () => {
    return hoursPerDay() * daysPerWeek();
  };

  const hoursInPayPeriod = () => {
    return daysPerWeek() * hoursPerDay() * (payPeriodDays() / 5);
  };

  const payPeriodGross = (altPayRate = null) => {
    let payRate =
      altPayRate || adminContext.currentEmployee.pay_rate_per_hour._decimal;
    let payPeriodWeeks = payPeriodDays() / 5;
    return Number(payPeriodWeeks * payRate * awardHoursPerWeek()).toFixed(2);
  };

  const annualGross = (altPayRate = null) => {
    let payRate =
      altPayRate || adminContext.currentEmployee.pay_rate_per_hour._decimal;
    return Number(payRate * 52 * awardHoursPerWeek()).toFixed(2);
  };

  const recalculatePaySettings = (e) => {
    // Update pay rate per hour, annual salary, and pay period gross depending on each other and hours/days per day/week respectively
    // Do not update the last changed value, update the other fields
    let valueToKeep = e.target.name;
    if (
      valueToKeep === "award_days_week" ||
      valueToKeep === "award_hours_day"
    ) {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        pay_period_gross: {
          _decimal: payPeriodGross(),
        },
        annual_gross: {
          _decimal: annualGross(),
        },
      });
    } else if (valueToKeep === "pay_rate_per_hour") {
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        pay_period_gross: {
          _decimal: payPeriodGross(Number(e.target.value._decimal)),
        },
        annual_gross: {
          _decimal: annualGross(Number(e.target.value._decimal)),
        },
        pay_rate_per_hour: e.target.value,
      });
    } else if (valueToKeep === "pay_period_gross") {
      let newRate = Number(e.target.value._decimal) / hoursInPayPeriod();
      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        pay_rate_per_hour: {
          _decimal: newRate,
        },
        annual_gross: {
          _decimal: annualGross(newRate),
        },
        pay_period_gross: e.target.value,
      });
    } else if (valueToKeep === "annual_gross") {
      let newRate =
        Number(e.target.value._decimal) / (daysPerWeek() * hoursPerDay() * 52);

      adminContext.setCurrentEmployee({
        ...adminContext.currentEmployee,
        pay_rate_per_hour: {
          _decimal: newRate,
        },
        pay_period_gross: {
          _decimal: payPeriodGross(newRate),
        },
        annual_gross: e.target.value,
      });
    }
  };

  const handleBaseOrdinaryRateSelection = (data) => {
    const selectedKey = adminContext.getKeyByValue(
      comboData["base_ordinary_rate"],
      data
    );
    const isNullSelection = selectedKey == null;

    // Extract the base ordinary rate from the selected combo item
    const baseOrdinaryRateMatch = data.match(/\[\$(\d+\.\d{5})\]/);
    const baseOrdinaryRateValue = baseOrdinaryRateMatch
      ? parseFloat(baseOrdinaryRateMatch[1])
      : null;

    const hasBaseOrdinaryRate =
      !isNullSelection && baseOrdinaryRateValue !== null;

    setHasBaseOrdinaryRate(hasBaseOrdinaryRate);

    // Create the updated employee object
    const updatedEmployee = {
      ...adminContext.currentEmployee,
      company_pay_rate_id: selectedKey,
    };

    // Conditionally update pay-related fields only if selection is not null and baseOrdinaryRateValue is valid
    if (!isNullSelection && baseOrdinaryRateValue !== null) {
      updatedEmployee.pay_rate_per_hour = {
        _decimal: baseOrdinaryRateValue,
      };
      updatedEmployee.pay_period_gross = {
        _decimal: payPeriodGross(baseOrdinaryRateValue),
      };
      updatedEmployee.annual_gross = {
        _decimal: annualGross(baseOrdinaryRateValue),
      };
    }

    adminContext.setCurrentEmployee(updatedEmployee);
  };

  // Reinitialize hasBaseOrdinaryRate when currentEmployee.id changes
  useEffect(() => {
    setHasBaseOrdinaryRate(
      adminContext.currentEmployee.company_pay_rate_id !== null
    );
  }, [adminContext.currentEmployee.id]);

  useEffect(() => {
    recalculatePaySettings({ target: { name: "award_days_week" } });
  }, [adminContext.currentEmployee.pay_period]);

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <AvForm onValidSubmit={saveChanges}>
              <Row>
                <Col>
                  <h1 className="days-one">Pay Rates</h1>
                </Col>
              </Row>
              <SaveChangesMessageRow
                errorMessage={errorMessage}
                successMessage={successMessage}
              />

              <Row>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      name="pay_method"
                      stretch={true}
                      label="Pay Method"
                      comboData={comboData["pay_method"]}
                      selectedComboItem={
                        adminContext.currentEmployee.pay_method
                      }
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          pay_method: adminContext.getKeyByValue(
                            comboData["pay_method"],
                            data
                          ),
                        })
                      }
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      stretch={true}
                      label="Pay Type"
                      name="pay_type"
                      comboData={comboData["pay_type"]}
                      selectedComboItem={adminContext.currentEmployee.pay_type}
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          pay_type: adminContext.getKeyByValue(
                            comboData["pay_type"],
                            data
                          ),
                        })
                      }
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      stretch={true}
                      name="pay_period"
                      label="Pay Period"
                      comboData={comboData["pay_period"]}
                      selectedComboItem={
                        adminContext.currentEmployee.pay_period
                      }
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          pay_period: adminContext.getKeyByValue(
                            comboData["pay_period"],
                            data
                          ),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      stretch={true}
                      name="employment_tenure"
                      label="Employment Tenure"
                      comboData={comboData["employment_tenure"]}
                      selectedComboItem={
                        adminContext.currentEmployee.employment_tenure_id
                      }
                      setSelectedComboItem={(tenure) => {
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          employment_tenure_id: adminContext.getKeyByValue(
                            comboData["employment_tenure"],
                            tenure
                          ),
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <Row>
                    <Col>
                      <div key={adminContext.currentEmployee.id}>
                        <ComboBox
                          disabled={
                            adminContext.currentEmployee.locked ||
                            !adminContext.editAccess("employees")
                          }
                          className="mx-3"
                          name="employment_type"
                          stretch={true}
                          label="Employment Type"
                          comboData={comboData["employment_type"]}
                          selectedComboItem={
                            adminContext.currentEmployee.employment_type_id
                          }
                          setSelectedComboItem={(data) =>
                            adminContext.setCurrentEmployee({
                              ...adminContext.currentEmployee,
                              employment_type_id: adminContext.getKeyByValue(
                                comboData["employment_type"],
                                data
                              ),
                            })
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      label="STP Employment Status"
                      stretch={true}
                      name="stp_employment_status"
                      comboData={comboData["stp_employment_status"]}
                      selectedComboItem={
                        adminContext.currentEmployee.stp_employment_status
                      }
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          stp_employment_status: adminContext.getKeyByValue(
                            comboData["stp_employment_status"],
                            data
                          ),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <AvField
                      label="Position/Role"
                      value={adminContext.currentEmployee.position}
                      onChange={handleChange}
                      type="text"
                      name="position"
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      label="Award"
                      stretch={true}
                      name="award"
                      comboData={comboData["award"]}
                      selectedComboItem={adminContext.currentEmployee.award_id}
                      setSelectedComboItem={(data) => {
                        // When an award is changed, the emps classification and base_ordinary_rate need to be wiped
                        let newEmployee = {
                          ...adminContext.currentEmployee,
                          classification_id: null,
                          company_pay_rate_id: null,
                          award_id: adminContext.getKeyByValue(
                            comboData["award"],
                            data
                          ),
                        };

                        setIsLoading(true);
                        adminContext.putRequest(
                          adminContext.constants.BASE_URL +
                            `/employees/update-employee`,
                          newEmployee,
                          (response) => {
                            initView();
                          }
                        );
                      }}
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      label="Classification"
                      stretch={true}
                      name="classification_select"
                      comboData={comboData["classification_select"]}
                      selectedComboItem={
                        adminContext.currentEmployee.classification_id
                      }
                      setSelectedComboItem={(data) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          classification_id: adminContext.getKeyByValue(
                            comboData["classification_select"],
                            data
                          ),
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <div className="mb-1">
                      <label>Award hours per day</label>
                    </div>
                    <HoursEdit
                      value={adminContext.currentEmployee.award_hours_day}
                      name="award_hours_day"
                      onBlur={recalculatePaySettings}
                      setValue={(value) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          award_hours_day: value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <div className="mb-1">
                      <label>Award days per week</label>
                    </div>
                    <FractionalDaysEdit
                      onBlur={recalculatePaySettings}
                      name="award_days_week"
                      value={adminContext.currentEmployee.award_days_week}
                      setValue={(value) =>
                        adminContext.setCurrentEmployee({
                          ...adminContext.currentEmployee,
                          award_days_week: value,
                        })
                      }
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <AvField
                    type="text"
                    name="income_stream"
                    label="STP Income Stream"
                    value={adminContext.currentEmployee.income_stream}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.pay_rate_per_hour}>
                    <MoneyEdit
                      amount={adminContext.currentEmployee.pay_rate_per_hour}
                      decimalPrecision={5}
                      onBlur={recalculatePaySettings}
                      name="pay_rate_per_hour"
                      label="Pay Rate Per Hour"
                      disabled={hasBaseOrdinaryRate}
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.pay_period_gross}>
                    <MoneyEdit
                      amount={adminContext.currentEmployee.pay_period_gross}
                      onBlur={recalculatePaySettings}
                      label="Pay Period Gross"
                      name="pay_period_gross"
                      step="0.01"
                      disabled={hasBaseOrdinaryRate}
                    />
                  </div>
                </Col>
                <Col lg="4" className="my-3">
                  <div key={adminContext.currentEmployee.annual_gross}>
                    <MoneyEdit
                      amount={adminContext.currentEmployee.annual_gross}
                      onBlur={recalculatePaySettings}
                      name="annual_gross"
                      label="Annual Gross"
                      step="0.01"
                      disabled={hasBaseOrdinaryRate}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="3" className="my-1">
                  <div key={adminContext.currentEmployee.id}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          color="primary"
                          className="mx-2"
                          name="active_pay_recipient"
                          checked={
                            adminContext.currentEmployee.active_pay_recipient
                          }
                          onChange={handleChange}
                        />
                      }
                      label={"Active Pay Recipient?"}
                    />
                  </div>
                </Col>
                <Col xl="3" className="my-1">
                  <div key={adminContext.currentEmployee.id}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          color="primary"
                          className="mx-2"
                          name="is_closely_held"
                          checked={adminContext.currentEmployee.is_closely_held}
                          onChange={handleChange}
                        />
                      }
                      label={"Is Closely Held?"}
                    />
                  </div>
                </Col>
                <Col xl="3" className="my-1">
                  <div key={adminContext.currentEmployee.id}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          color="primary"
                          className="mx-2"
                          name="copy_hours_from_last_pay"
                          checked={
                            adminContext.currentEmployee
                              .copy_hours_from_last_pay
                          }
                          onChange={handleChange}
                        />
                      }
                      label={"Copy hours from last pay?"}
                    />
                  </div>
                </Col>
                <Col xl="3" className="my-1">
                  <div key={adminContext.currentEmployee.id}>
                    <FormControlLabel
                      control={
                        <CheckBox
                          color="primary"
                          className="mx-2"
                          name="ignore_roster_shifts"
                          checked={
                            adminContext.currentEmployee.ignore_roster_shifts
                          }
                          onChange={handleChange}
                        />
                      }
                      label={"Ignore roster shifts when generating pays?"}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6" className="my-3">
                  <div key={adminContext.currentEmployee.id}>
                    <ComboBox
                      disabled={
                        adminContext.currentEmployee.locked ||
                        !adminContext.editAccess("employees")
                      }
                      className="mx-3"
                      stretch={true}
                      name="base_ordinary_rate"
                      label="Base Ordinary Rate (Requires company pay rates)"
                      comboData={comboData["base_ordinary_rate"]}
                      selectedComboItem={
                        adminContext.currentEmployee.company_pay_rate_id
                      }
                      setSelectedComboItem={handleBaseOrdinaryRateSelection}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="4" className="my-3">
                  <Row>
                    <Col>
                      <div key={adminContext.currentEmployee.id}>
                        <h3>
                          Company pay rate groups - Classification (Award)
                        </h3>
                        <p>E.g. Full Time (Nurses Award 2010)</p>
                        <ListWidget
                          name="pay_rate_groups"
                          rows={rows}
                          setRows={setRows}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AvForm>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default PayRatesTab;
