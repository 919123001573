// reactstrap components
import { Button, Row, Col, Container, CardBody, Card, Alert } from "reactstrap";
import { useState, useContext } from "react";
import AdminContext from "../../AdminContext";
import dayjs from "dayjs";

import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import DateEdit from "../../components/Widgets/DateEdit";
import ComboBox from "../../components/Widgets/ComboBox";
import BackButton from "../../components/Widgets/BackButton";
import EmployeeListWidget from "../../components/Widgets/EmployeeListWidget";

const BulkTermination = () => {
  const adminContext = useContext(AdminContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: [],
  });
  const [comboData, setComboData] = useState({
    reason: "V",
  });

  const [terminationDate, setTerminationDate] = useState(new Date());

  const handleDateChange = (dateTime) => {
    if (!dayjs(dateTime).isValid()) {
      setErrorMessage("Please enter a valid date.");
      return;
    } else {
      setErrorMessage("");
    }
    setTerminationDate(dateTime);
  };

  const handleBulkTermination = () => {
    setErrorMessage("");
    setSuccessMessage("");
    setIsLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
        "/employees/bulk-termination?company_id=" +
        adminContext.company.id,
      {
        termination_date: dayjs(terminationDate).format("YYYY-MM-DD"),
        reason: comboData.reason,
        employee_ids: selectedRows.employees.map((employee) => employee.id),
      },
      (response) => {
        setIsLoading(false);
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          setSuccessMessage("Successfully terminated employees.");
        }

        if (response.data.company) {
          adminContext.setCompany(response.data.company);
          setRows({
            employees: response.data.company.employees,
          });
          setSelectedRows({
            employees: [],
          });
        }
      }
    );
  };

  return (
    <Container className="my-3 overflow-hidden" fluid>
      <Card>
        <CardBody>
          {!isLoading ? (
            <>
              <div className="text-left mt-2 mb-3">
                <BackButton />
                <h1 className="days-one my-2">Bulk Termination Wizard</h1>

                <p>
                  This wizard allows you to terminate multiple employees in one
                  go. This is intended for use when you are limited by the
                  number of companies you are licenced for. Once all employees
                  are terminated, the company will no longer count towards your
                  licence limit.
                </p>
                <p>
                  <strong>
                    This tool can only process very basic zero-dollar
                    terminations. If you need to process more complex
                    terminations, please do so manually from within the Pays
                    screen. Learn more about terminations{" "}
                    <a
                      href="https://www.lightningpayroll.com.au/faq?selected=218"
                      target="_blank"
                    >
                      here on our support page
                    </a>
                    .
                  </strong>
                </p>
                <hr />
                {errorMessage && (
                  <Alert color="warning">
                    <strong>{errorMessage}</strong>
                  </Alert>
                )}
                {successMessage && (
                  <Alert color="success">
                    <strong>{successMessage}</strong>
                  </Alert>
                )}

                <p>
                  Please enter the termination date and the reason for
                  termination.
                </p>
                <p>
                  Note: You will not be able to proceed if the termination date
                  is earlier than an employee\'s start date, or in the future.
                </p>
                <Row>
                  <Col>
                    <DateEdit
                      label="Termination Date"
                      value={terminationDate}
                      onChange={(date) => handleDateChange(date)}
                      type="date"
                      name="termination_date"
                    />
                  </Col>
                  <Col className="mx-1">
                    <ComboBox
                      className="mx-3"
                      label="Termination Reason"
                      stretch={true}
                      disabled={!adminContext.editAccess("employees")}
                      name="reason"
                      comboData={adminContext.constants.CESSATION_TYPES}
                      selectedComboItem={comboData.reason}
                      setSelectedComboItem={(data) => {
                        setComboData({
                          ...comboData,
                          reason: adminContext.getKeyByValue(
                            adminContext.constants.CESSATION_TYPES,
                            data
                          ),
                        });
                      }}
                    />
                  </Col>
                </Row>
                <EmployeeListWidget
                  name="employees"
                  rows={rows}
                  setRows={setRows}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  hideShowTerminated={true}
                />
                <hr />
                <Button
                  color="primary"
                  onClick={handleBulkTermination}
                  disabled={selectedRows.employees.length === 0}
                >
                  Terminate Selected Employees ( {selectedRows.employees.length}
                  /{rows.employees.filter((emp) => !emp.is_terminated).length}{" "}
                  Selected )
                </Button>
              </div>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default BulkTermination;
