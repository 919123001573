import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import UserHeader from "../../components/Headers/UserHeader.js";
import reportNames from "../../variables/reportNames.js";
import Tooltip from "@mui/material/Tooltip";

const ReportLink = ({ reportKey, reportDetails }) => (
  <Col lg="6" xl="4">
    <Link
      to={`/admin/reports/${reportKey}`}
      className="btn-icon-clipboard border-radius-45 min-height-auto align-items-start semi-active-button"
    >
      {reportDetails.short_description ? (
        <Tooltip title={reportDetails.short_description} placement="bottom">
          <div>
            <i className="fa-regular fa-lg fa-file-chart-column" />
            <span className="text-wrap">{reportDetails.name}</span>
          </div>
        </Tooltip>
      ) : (
        <div>
          <i className="ni ni-archive-2" />
          <span className="text-wrap">{reportDetails.name}</span>
        </div>
      )}
    </Link>
  </Col>
);

const ReportCategory = ({ category, reports }) => (
  <Row className="width-100">
    <Col className="col-md-12">
      <h2 className="days-one mx-2">{category}</h2>
      <Row>
        {reports.map((reportKey) => (
          <ReportLink
            key={reportKey}
            reportKey={reportKey}
            reportDetails={reportNames[reportKey]}
          />
        ))}
      </Row>
      <hr />
    </Col>
  </Row>
);

const Reports = () => {
  const categorizedReports = Object.keys(reportNames).reduce(
    (acc, reportKey) => {
      const category = reportNames[reportKey].category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(reportKey);
      return acc;
    },
    {}
  );

  return (
    <>
      <UserHeader />
      <Container className="mt--5" fluid>
        <Row className="mx-0">
          <Col>
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h1 className="mb-0 mx-auto text-center days-one">Reports</h1>
              </CardHeader>
              <CardBody>
                <Row className="icon-examples">
                  {Object.entries(categorizedReports).map(
                    ([category, reports]) => (
                      <ReportCategory
                        key={category}
                        category={category}
                        reports={reports}
                      />
                    )
                  )}
                  <Row className="width-100">
                    <Col className="col-md-12">
                      <h2 className="days-one mx-2">Custom Reports</h2>
                      <Row>
                        <Col lg="6" xl="4">
                          <Link
                            to={`/admin/custom-reports/builder/pay`}
                            className="btn-icon-clipboard border-radius-45 min-height-auto align-items-start semi-active-button"
                          >
                            <div>
                              <i className="fa-regular fa-lg fa-file-chart-column" />
                              <span className="text-wrap">
                                Pay Report Builder
                              </span>
                            </div>
                          </Link>
                        </Col>
                        <Col lg="6" xl="4">
                          <Link
                            to={`/admin/custom-reports/builder/employee`}
                            className="btn-icon-clipboard border-radius-45 min-height-auto align-items-start semi-active-button"
                          >
                            <div>
                              <i className="fa-regular fa-lg fa-file-chart-column" />
                              <span className="text-wrap">
                                Employee Report Builder
                              </span>
                            </div>
                          </Link>
                        </Col>
                        <Col lg="6" xl="4">
                          <Link
                            to={`/admin/custom-reports/launch`}
                            className="btn-icon-clipboard border-radius-45 min-height-auto align-items-start semi-active-button"
                          >
                            <div>
                              <i className="fa-regular fa-lg fa-file-chart-column" />
                              <span className="text-wrap">
                                Launch Custom Reports
                              </span>
                            </div>
                          </Link>
                        </Col>
                      </Row>
                      <hr />
                    </Col>
                  </Row>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Reports;
