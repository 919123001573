const VALID_SMSF_ESAS = [
    { alias: "AUSPOSTSMSF", start: "8/05/2022", end: "31/12/9999" },
    { alias: "CFSCSSMSF", start: "4/09/2014", end: "31/12/9999" },
    { alias: "ClearviewSMSF", start: "4/09/2014", end: "31/12/9999" },
    { alias: "ESUPERFUND", start: "18/10/2021", end: "31/12/9999" },
    { alias: "MACQUARIESMSF", start: "4/08/2014", end: "31/12/9999" },
    { alias: "MercerSMSF", start: "4/09/2014", end: "31/12/9999" },
    { alias: "netwealthsmsf", start: "3/11/2014", end: "31/12/9999" },
    { alias: "QUICKSUPER", start: "4/08/2014", end: "31/12/9999" },
    { alias: "smsfdataflow", start: "30/08/2021", end: "31/12/9999" },
    { alias: "SUPERCHOICE", start: "4/09/2014", end: "31/12/9999" },
    { alias: "SuperMate", start: "27/09/2021", end: "31/12/9999" },
    { alias: "TheSMSFManagers", start: "1/07/2015", end: "31/12/9999" },
    { alias: "BGLSF360", start: "27/09/2021", end: "31/12/9999" },
    { alias: "wrkrSMSF", start: "15/09/2024", end: "31/12/9999" },
];

const VALID_SMSF_ESA_ALIASES = VALID_SMSF_ESAS.map(esa => esa.alias);

function validateESA(eaddress) {
    // Validate ESA
    for (const esa of VALID_SMSF_ESAS) {
        if (esa.alias === eaddress) {
            // Parse the start and end dates
            const startDate = new Date(esa.start.split("/").reverse().join("-"));
            const endDate = new Date(esa.end.split("/").reverse().join("-"));
            const today = new Date();

            // Check if today's date is within the range
            if (startDate <= today && today <= endDate) {
                return ""; // Valid ESA
            } else {
                return `ESA "${eaddress}" is not valid as of today's date. Please contact the SMSF provider.`;
            }
        }
    }

    // If ESA not found in the valid list
    return `ESA "${eaddress}" is not a recognised SMSF alias. Electronic address must be one of the following: ${VALID_SMSF_ESA_ALIASES.join(", ")}.`;

}


export default validateESA;