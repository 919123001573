import AdminContext from "../../AdminContext";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  Alert,
} from "reactstrap";

import SelectEntity from "../Entities/SelectEntity";
import EmployeesSelect from "../../components/Widgets/EmployeesSelect";

const CopyEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newEmployee, setNewEmployee] = useState({});
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [copySuccessModalOpen, setCopySuccessModalOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(false);
  const [originalCompany, setOriginalCompany] = useState(null);
  const [originalEmployee, setOriginalEmployee] = useState(null);

  const adminContext = useContext(AdminContext);

  useEffect(() => {
    // Save the original company and employee when component mounts
    setOriginalCompany(adminContext.company);
    setOriginalEmployee(adminContext.currentEmployee);
  }, []);

  // Update the API on submit
  const saveChanges = () => {
    setIsLoading(true);
    setDeleteConfirmationOpen(false);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/employees/copy?employee_id=${adminContext.currentEmployee.id}&company_id=${selectedCompanyId}`,
      newEmployee,
      (response) => {
        setIsLoading(false);
        // Restore the original company and employee
        adminContext.setCompany(originalCompany);
        adminContext.setCurrentEmployee(originalEmployee);
        // Show the success modal
        setCopySuccessModalOpen(true);
      }
    );
  };

  const getEmployeeName = () => {
    return (
      adminContext.currentEmployee.first_name +
      " " +
      adminContext.currentEmployee.last_name
    );
  };

  const confirmCopyEmployee = (e) => {
    e.preventDefault();
    setSelectedCompanyId(e.currentTarget.id);
    setDeleteConfirmationOpen(true);
  };

  const getCompany = () => {
    let company = adminContext.companies.find(
      (company) => Number(company.id) === Number(selectedCompanyId)
    );
    if (company) {
      return company;
    }
    return false;
  };

  return (
    <>
      <Container className="mt-4" fluid>
        {adminContext.currentEmployee.id ? (
          <>
            <Card className="bg-secondary shadow">
              <CardBody>
                {!isLoading ? (
                  <>
                    <Row>
                      <Col>
                        <h1 className="days-one">Copy Employee</h1>
                        <p>
                          This screen lets you create a new employee profile on
                          another business entity, using the basic profile
                          settings of <strong>{getEmployeeName()}</strong> as a
                          template.
                        </p>{" "}
                      </Col>{" "}
                    </Row>
                    <Row>
                      <Col>
                        <SelectEntity handleClick={confirmCopyEmployee} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <LoadingSpinner />
                )}
              </CardBody>
            </Card>
            <Modal isOpen={deleteConfirmationOpen && getCompany()}>
              <div className="mx-4 my-4 ">
                <p className="text-center">
                  Are you sure you would like to copy{" "}
                  <strong>{getEmployeeName()}</strong> to{" "}
                  <strong>{getCompany().name}</strong>?
                </p>
                <Button
                  color="warning"
                  className="float-right mb-2 mx-2"
                  onClick={saveChanges}
                >
                  Yes
                </Button>
                <Button
                  color="secondary"
                  className="float-right mb-2 mx-2"
                  onClick={() => {
                    setDeleteConfirmationOpen(false);
                  }}
                >
                  No, cancel
                </Button>
              </div>
            </Modal>
            <Modal
              isOpen={copySuccessModalOpen}
              toggle={() => setCopySuccessModalOpen(false)}
            >
              <div className="mx-4 my-4">
                <h2 className="text-center mb-0">Employee Copied</h2>
              </div>
              <p className="text-center mb-4">
                The employee has been copied successfully.
                <br />
                Items such as leave balances, bank accounts, and super fund
                details
                <br />
                have also been copied. Please delete these if you didn't mean
                for them
                <br />
                to be brought over.
              </p>
              <div className="d-flex justify-content-center mb-4">
                <Button
                  color="warning"
                  onClick={() => {
                    setCopySuccessModalOpen(false);
                    adminContext.history.goBack();
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal>
          </>
        ) : (
          <Container>
            <Row>
              <Col>
                <EmployeesSelect
                  className="text-left float-left"
                  title="Please select an employee"
                />
              </Col>
              <Col></Col>
            </Row>
          </Container>
        )}
      </Container>
    </>
  );
};

export default CopyEmployee;
