import { useState, useEffect, useContext } from "react";
import EditForm from "../../../../components/Widgets/EditForm";
import AdminContext from "../../../../AdminContext";
import LoadingSpinner from "../../../../components/Widgets/LoadingSpinner";
import QuestionModal from "../../../../components/Widgets/QuestionModal";

const SingleTouchSelectionView = ({
  setNextEnabled,
  wizardInfo,
  setWizardInfo,
  rows,
  setRows,
  selectedRows,
  setSelectedRows,
  loading,
  setLoading,
}) => {
  const adminContext = useContext(AdminContext);
  const [zeroDollarConfirmationOpen, setZeroDollarConfirmationOpen] =
    useState(false);

  const initWizardInfo = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/pays/single-touch/init`,
      wizardInfo,
      (response) => {
        let serviceAction = "submit";
        if (
          !response.data.new_event_employees ||
          response.data.new_event_employees.length === 0
        ) {
          serviceAction = "update";
        }

        // update the wizard info
        setWizardInfo({
          ...wizardInfo,
          supplier_info: response.data.supplier_info,
          emp_ytd: response.data.emp_ytd,
          financial_year: response.data.financial_year,
          new_event_employees: response.data.new_event_employees,
          update_event_employees: response.data.update_event_employees,
          processed_date_rationale: response.data.processed_date_rationale,
          processed_date: response.data.processed_date,
          likely_last_pay_of_fy: response.data.likely_last_pay_of_fy,
          company_declaration_required:
            response.data.company_declaration_required,
          supplier_declaration_required:
            response.data.supplier_declaration_required,
          web_app_ato_declaration_required:
            response.data.web_app_ato_declaration_required,
          service_action: serviceAction,
        });
        setLoading(false);
      }
    );
  };

  // Update the rows depending on the service_action
  useEffect(() => {
    if (wizardInfo.service_action === "submit") {
      setRows({
        employees: wizardInfo.new_event_employees,
      });
      setSelectedRows({
        employees: wizardInfo.new_event_employees,
      });
    } else {
      setRows({
        employees: wizardInfo.update_event_employees,
      });
      setSelectedRows({
        employees: wizardInfo.update_event_employees,
      });
    }
  }, [wizardInfo.service_action]);

  useEffect(() => {
    initWizardInfo();
  }, []);

  const nonDeletedEmployeesFilter = (employee) => {
    if (employee.is_deleted) {
      return false;
    }
    return true;
  };

  // setNextEnabled to false unless some employee rows have been selected then they can go next
  useEffect(() => {
    let nextEnabled = false;
    if (wizardInfo.service_action === "adjust") {
      // Check if either `gross_adj` or `tax_adj` is a non-zero number
      const grossAdjValid =
        parseFloat(wizardInfo.gross_adj._decimal) !== 0 && wizardInfo.gross_adj;
      const taxAdjValid =
        parseFloat(wizardInfo.tax_adj._decimal) !== 0 && wizardInfo.tax_adj;

      if (grossAdjValid || taxAdjValid) {
        nextEnabled = true;
      }
    } else if (selectedRows.employees.length > 0) {
      // For non-adjust events, check if employees are selected
      nextEnabled = true;
    }

    setNextEnabled(nextEnabled);
  }, [
    selectedRows,
    wizardInfo.service_action,
    wizardInfo.gross_adj,
    wizardInfo.tax_adj,
  ]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <EditForm
            liveEdit={true}
            formSpec={{
              title: "Single Touch Selection",
              fixedTitle: true,
              fields: [
                {
                  label:
                    "Please select the employees you would like to submit Single Touch Payroll figures for.",
                  accessor: "employees",
                  widget: "ListWidget",
                  args: {
                    filterCallback: nonDeletedEmployeesFilter,
                    rows: rows,
                    setRows: setRows,
                    selectedRows: selectedRows,
                    setSelectedRows: setSelectedRows,
                    visibleCallback: (modelObj) => {
                      return modelObj.service_action !== "adjust";
                    },
                  },
                },
                {
                  label: "Gross (W1) Adjustment Amount",
                  accessor: "gross_adj",
                  widget: "MoneyEdit",
                  args: {
                    visibleCallback: (modelObj) => {
                      return modelObj.service_action === "adjust";
                    },
                  },
                },
                {
                  label: "Tax (W2) Adjustment Amount",
                  accessor: "tax_adj",
                  widget: "MoneyEdit",
                  args: {
                    visibleCallback: (modelObj) => {
                      return modelObj.service_action === "adjust";
                    },
                  },
                },
                {
                  label: "Event Type",
                  widget: "GroupBox",
                  accessor: "service_action",
                  infoText: (
                    <a href="https://www.lightningpayroll.com.au/faq?selected=176">
                      What is the difference between these event types?
                    </a>
                  ),
                  args: {
                    className: "heavy-font",
                    children: [
                      {
                        label:
                          "Create a new/pay event (additional YTD figures)",
                        accessor: "submit",
                      },
                      {
                        label: "Create an update event (refresh YTD figures)",
                        accessor: "update",
                      },

                      {
                        label:
                          "Adjust incorrect pay/new event parent totals (YTD figures n/a).",
                        accessor: "adjust",
                      },
                    ],
                  },
                },
                {
                  label: "Submit as a third party/tax agent?",
                  accessor: "submit_as_supplier",
                  widget: "CheckBox",
                },
                {
                  label: "",
                  widget: "GroupBox",
                  accessor: "submit_as",
                  args: {
                    visibleCallback: (modelObj) => {
                      return modelObj.submit_as_supplier;
                    },
                    children: [
                      {
                        label: "Submit as a Registered Agent",
                        accessor: "registered_agent",
                      },
                      {
                        label: "Submit as a Business Intermediary",
                        accessor: "business_intermediary",
                      },
                    ],
                  },
                },
                {
                  label: "Submit zero dollar values?",
                  accessor: "submit_zero_dollars",
                  widget: "CheckBox",
                  args: {
                    additionalChangeCallback: (e) => {
                      setZeroDollarConfirmationOpen(e.target.checked);
                    },
                    visibleCallback: (modelObj) => {
                      return modelObj.service_action !== "adjust";
                    },
                  },
                },
                {
                  label: "Final pay for the financial year?",
                  accessor: "final_pay_indicator",
                  widget: "CheckBox",
                  args: {
                    visibleCallback: (modelObj) => {
                      return modelObj.service_action !== "adjust";
                    },
                  },
                },
                {
                  label: "Processed Date Rationale",
                  accessor: "processed_date_rationale",
                  widget: "Label",
                },
                {
                  label: "Financial Year",
                  accessor: "financial_year",
                  widget: "Label",
                  valueCallback: (modelObj) => {
                    return (
                      "Financial Year: " +
                      modelObj.financial_year.financial_year
                    );
                  },
                },
              ],
            }}
            setModelObj={setWizardInfo}
            modelObj={wizardInfo}
          />
          <QuestionModal
            isOpen={zeroDollarConfirmationOpen}
            title=""
            content={
              <>
                <p>
                  Are you sure you would like to send a $0.00 STP Event for each
                  of the selected employees to the ATO?
                </p>
                <p>This option should be used with caution.</p>
              </>
            }
            onConfirm={() => {
              setZeroDollarConfirmationOpen(false);
              setWizardInfo({
                ...wizardInfo,
                submit_zero_dollars: true,
              });
            }}
            onDeny={() => {
              setZeroDollarConfirmationOpen(false);
              setWizardInfo({
                ...wizardInfo,
                submit_zero_dollars: false,
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default SingleTouchSelectionView;
