import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import AdminContext from "../../../AdminContext";
import dayjs from "dayjs";
import DateEdit from "../../../components/Widgets/DateEdit";
import SaveChangesMessageRow from "../../../components/Widgets/SaveChangesMessageRow";

const EmploymentDatesTab = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const getEmployeeId = () => {
    if (adminContext.currentEmployee.id !== undefined) {
      return adminContext.currentEmployee.id;
    }
    adminContext.company.employees.forEach((employee) => {
      return employee.id;
    });
  };

  const initView = async () => {
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/employees/${getEmployeeId()}/init`
    );
  };
  useEffect(() => {
    initView();
  }, []);

  // Update the API on submit
  const saveChanges = (e) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL + `/employees/update-employee`,
      adminContext.currentEmployee,
      (response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Changes saved successfully");
        } else {
          setErrorMessage("Something went wrong. Unable to save changes.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  const handleDateChange = (label, accessor, dateTime) => {
    console.log("dateTime is");
    console.log(dateTime);
    if (!dayjs(dateTime).isValid()) {
      setErrorMessage(label + " is not valid");
      return;
    } else {
      setErrorMessage("");
    }
    adminContext.setCurrentEmployee({
      ...adminContext.currentEmployee,
      [accessor]: dateTime,
    });
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Employment Dates</h1>
                </Col>
              </Row>
              <SaveChangesMessageRow
                disabledCallback={() =>
                  adminContext.currentEmployee.locked || errorMessage
                }
                errorMessage={errorMessage}
                successMessage={successMessage}
                saveChanges={saveChanges}
              />
              <Row>
                <Col>
                  <DateEdit
                    label="Start Date"
                    value={adminContext.currentEmployee.start_date}
                    onChange={(date) =>
                      handleDateChange("Start Date", "start_date", date)
                    }
                    type="date"
                    name="start_date"
                  />
                </Col>
              </Row>
              {adminContext.currentEmployee.termination_date ? (
                <Row>
                  <Col>
                    <DateEdit
                      name="termination_date"
                      label="Termination Date"
                      value={adminContext.currentEmployee.termination_date}
                      disabled={true}
                    />
                  </Col>
                </Row>
              ) : null}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default EmploymentDatesTab;
